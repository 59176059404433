import React from 'react';
import { getFeatureFlagValue } from '@citrite/feature-flags';
import { generateRID } from '@sharefile/rid-utils';
import { PageComponentProps, PiletApi } from '@sharefiledev/sharefile-appshell';
import { LaunchFlowButton } from './blocks';
import { AppContextProvider } from './common';
import {
	configureDominoApiClient,
	configureDominoApiClient_Legacy,
	configureRightSignatureApiClient,
} from './data/clients';
import {
	AsyncContextualActions,
	AsyncContextualWorkflowList,
} from './extensions/Extensions';
import { configureLaunchFlowExtensions } from './extensions/launchFlowExtensions';
import { FeatureFlag } from './FeatureFlags';
import { configureFileExportAppShellExtension } from './FileExport/extensions/configureFileExportAppShellExtension';
import { configureFileExportProjectsExtension } from './FileExport/extensions/configureFileExportProjectsExtension';
import { configureFormExtensions } from './Form';
import { setLogger } from './logger';
import { registerNonProdDevPages } from './nonProdDevPages';
import {
	AsyncStorageFileExportModal,
	getDefaultModalProps,
	LaunchFlowOverlay,
} from './overlays';
import { AsyncDashboardPage } from './Pages';
import {
	checkRolesAndPreferences,
	isVdr,
	RenderConditionally,
	RolesAndProvisioningRequirements,
} from './provisioning';
import { workflowListPath } from './routes';
import {
	workflowsPiletBlocks,
	workflowsPiletExtensionSlots,
	workflowsPiletOverlays,
} from './sdk';
import { configureStartFlowContextualExtensions } from './StartFlow';
import WorkflowsContextProvider from './useWorkflowsContext';
import { isEUAccount, t, withDefaultProviders } from './util';

export function setup(app: PiletApi) {
	setLogger(app.sf.getLogger());

	if (isEUAccount() && !getFeatureFlagValue(FeatureFlag.EnableWorkflowsPiletForEU)) {
		return;
	}

	configureApiClients(app);

	registerLeftNavEntries(app);
	registerDashboard(app);
	registerOverlays(app);
	registerExtensions(app);
	handleDevToolingRegistrations(app);
}

const handleDevToolingRegistrations = (app: PiletApi) => {
	if (process.env.NODE_ENV === 'development') {
		registerNonProdDevPages(app);
	}
};

const getRolesProvisioningRequirements = (app: PiletApi) => {
	const requirements: RolesAndProvisioningRequirements = {
		requiredRoles: ['Employee'],
		requiredASTs: ['EnableAutomatedWorkflows'],
	};

	if (isVdr(app)) {
		requirements.requiredLaunchDarklyFeatureFlags = [
			FeatureFlag.EnableAutomatedWorkflowsVDR,
		];
	}

	return requirements;
};

function registerLeftNavEntries(app: PiletApi) {
	app.sf.registerLeftNavChildComponent({
		parent: '/workflows',
		href: workflowListPath,
		title: () => t('workflows-pilet:titles.dashboard') ?? '...',
		isAvailable: () =>
			checkRolesAndPreferences(app, getRolesProvisioningRequirements(app)),
		weight: 1,
	});
}

function configureApiClients(app: PiletApi) {
	if (getFeatureFlagValue(FeatureFlag.EnableBFFOnWorkflowsPilet)) {
		// Not being called currently
		configureDominoApiClient(app);
	} else {
		configureDominoApiClient_Legacy();
	}
	configureRightSignatureApiClient();
}

function registerDashboard(api: PiletApi) {
	const DashboardComponent = (props: PageComponentProps) => (
		<RenderConditionally
			{...props}
			piletApi={api}
			requirements={getRolesProvisioningRequirements(api)}
			redirectToDashboard={true}
		>
			<AsyncDashboardPage {...props} piletApi={api} />
		</RenderConditionally>
	);
	api.registerPage(workflowListPath, DashboardComponent);
}

function registerOverlays(piletApi: PiletApi) {
	const { user, account } = piletApi.sf.piletUserContext.get();

	piletApi.registerModal(
		workflowsPiletOverlays.launchFlow,
		withDefaultProviders(piletApi, props => (
			<WorkflowsContextProvider value={{ user, account }}>
				<LaunchFlowOverlay {...props} {...props.options} />
			</WorkflowsContextProvider>
		)),
		getDefaultModalProps({
			titleText: t('workflows-pilet:user_actions.start_flow'),
			isFullscreen: true,
		})
	);

	piletApi.registerModal(
		workflowsPiletOverlays.fileExport,
		withDefaultProviders(
			piletApi,
			({ options, cancelInteraction, completeInteraction }) => (
				<AsyncStorageFileExportModal
					cancelInteraction={cancelInteraction}
					completeInteraction={completeInteraction}
					{...options}
				/>
			)
		),
		{
			modalProps: {
				title: t('workflows-pilet:file_export.export'),
				destroyOnClose: true,
				className: 'modal--fileExport',
				footer: false,
			},
		}
	);
}

function registerExtensions(app: PiletApi) {
	const { user, account } = app.sf.piletUserContext.get();
	configureStartFlowContextualExtensions(app);
	configureFormExtensions(app);
	configureFileExportAppShellExtension(app);
	configureFileExportProjectsExtension(app);
	if (getFeatureFlagValue(FeatureFlag.EnableMarketplace)) {
		configureLaunchFlowExtensions(app);
	}

	app.registerExtension(
		workflowsPiletBlocks.launchFlow,
		withDefaultProviders(app, ({ params }) => (
			<AppContextProvider value={{ piletApi: app }}>
				<WorkflowsContextProvider value={{ user, account }}>
					<LaunchFlowButton {...params} piletApi={app} />
				</WorkflowsContextProvider>
			</AppContextProvider>
		))
	);

	app.registerExtension(
		workflowsPiletExtensionSlots.contextualWorkflowList,
		withDefaultProviders(app, ({ params }) => (
			<AppContextProvider value={{ piletApi: app }}>
				<WorkflowsContextProvider value={{ user, account }}>
					<AsyncContextualWorkflowList {...params} />
				</WorkflowsContextProvider>
			</AppContextProvider>
		)),
		{
			containerRID: generateRID({ baseEntityId: account.Id }),
			showSimpleView: true,
		}
	);

	app.registerExtension(
		workflowsPiletExtensionSlots.contextualActions,
		withDefaultProviders(app, ({ params }) => (
			<AppContextProvider value={{ piletApi: app }}>
				<WorkflowsContextProvider value={{ user, account }}>
					<AsyncContextualActions {...params} />
				</WorkflowsContextProvider>
			</AppContextProvider>
		))
	);
}
