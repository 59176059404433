/**
 * These are all runtime dependencies. Any breaking changes
 * will need a migration path first!
 */
import { Parameter } from '@sharefile/domino-types';
import type { Tag } from '@sharefile/tags-client';
import type {} from '@sharefiledev/sharefile-appshell'; // force tsc to resolve this package
import { ButtonProps } from 'antd';
import type {
	ActionView,
	Context,
	ContextualAction,
} from '../extensions/contextual-actions/types';
import { LocaleString } from '../util';

export interface FormData {
	isValid: boolean;
	data: Record<string, any>;
}

export interface FormProps {
	params: Parameter[];
	onDataChange(data: FormData): void;
	disabled: boolean;
	applyValidation: boolean;
}

export interface ContextualWorkflowListProps {
	containerRID?: string; // defaults to account RID
	showSimpleView?: boolean;
	templateTags?: Tag[];
	context?: {
		[key: string]: any;
	};
}

export interface ContextualActionsProps {
	actions: ContextualAction[];
	context: Context;
	view?: ActionView;
}

export interface ExportableFile {
	name: string;
	rid: string;
}

export interface FileExportModalParams {
	files: ExportableFile[];
}

const prefix = 'urn';
const extensionNamespace = 'sfextslot';
const owner = 'workflows-pilet';
const modalNamespace = 'sfmodal';
const blockNamespace = 'block';

export interface LaunchFlowOverlayParams {
	flowRID: string;
}

export interface LaunchFlowBlockParams {
	flowRID: string;
	buttonText: LocaleString;
	buttonProps?: Omit<ButtonProps, 'onClick'>;
	collectUserInput?: boolean;
	context?: {
		[key: string]: any;
	};
	confirmationModalText?: LocaleString;
	onSuccess?: (data: any) => void;
	onError?: (data: any) => void;
}

export const workflowsPiletOverlays = {
	launchFlow: `${prefix}:${modalNamespace}:${owner}:launch-flow` as const,
	fileExport: `${prefix}:${modalNamespace}:${owner}:file-export` as const,
};

export const workflowsPiletBlocks = {
	launchFlow: `${prefix}:${blockNamespace}:${owner}:launch-flow` as const,
};

export const workflowsPiletExtensionSlots = {
	dominoFormRendererSlot:
		`${prefix}:${extensionNamespace}:${owner}:domino:form:renderer` as const,
	prospectiveClientsListContextualActionSlot:
		'sharefile_ui-prospective_client-context_action' as const,
	clientsListContextualActionSlot: 'sharefile_ui-client-context_action' as const,
	createOptionsDropdown:
		`${prefix}:${extensionNamespace}:${owner}:workflow:create:option` as const,
	optionsDropdown: `${prefix}:${extensionNamespace}:${owner}:workflow:option` as const,
	contextualWorkflowList:
		`${prefix}:${extensionNamespace}:${owner}:contextual-workflow-list` as const,
	contextualActions:
		`${prefix}:${extensionNamespace}:${owner}:contextual-actions` as const,
};

declare module '@sharefiledev/sharefile-appshell' {
	interface PiralCustomExtensionSlotMap {
		[workflowsPiletExtensionSlots.dominoFormRendererSlot]: FormProps;
		[workflowsPiletExtensionSlots.contextualWorkflowList]: ContextualWorkflowListProps;
		[workflowsPiletExtensionSlots.contextualActions]: ContextualActionsProps;
	}

	interface PiralCustomOverlayOptionsMap {
		[workflowsPiletOverlays.launchFlow]: LaunchFlowOverlayParams;
		[workflowsPiletBlocks.launchFlow]: LaunchFlowBlockParams;
		[workflowsPiletOverlays.fileExport]: FileExportModalParams;
	}
}

export interface ValueErrorResult {
	error?: string;
}

export type PluginProvidedEntitySelectorExtensionSlotParams<TDominoEntityType = any> = {
	/** The entity value OR an id reference to it **/
	configuredValue: TDominoEntityType | string;
	/** Update state value in flow designer. Providing a ValueErrorResult invalidate the flow. **/
	configureValue: (value: TDominoEntityType | ValueErrorResult) => void;
	/** Callback to provide an validation error that will not be shown until flow submissions. */
	onError: (value?: ValueErrorResult) => void;
	/** State variable to know if an error was provided */
	isDominoBuilderError: boolean;
	/** Error message */
	error?: string;
	/** Option to writeback the entity when an entity id reference is provided. */
	allowHydratedWriteback?: boolean;
};
